


















































































































































































































































































































































































































































































































































































.menu__item {
    width: 100%;
    display: block;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
    text-align: center;

}
